<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form
          class="search"
          :inline="true"
          label-suffix="："
          size="mini"
          :model="search"
        >
          <el-form-item label="客户名称">
            <el-input
              v-model="search.customer_name"
              clearable
              placeholder="请输入客户名称"
            />
          </el-form-item>
          <el-form-item label="门店名称">
            <el-input
              v-model="search.shop_name"
              clearable
              placeholder="请输入门店名称"
            />
          </el-form-item>
          <el-form-item label="市场">
            <el-select
              v-model="search.market_id"
              clearable
              placeholder="请选择市场"
            >
              <el-option
                v-for="item in options.market_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList('search')"
          >搜索</el-button
        >
      </el-col>
    </el-row>
    <div class="accountCon">
      <el-button
        type="primary"
        v-if="!userInfo.shop_id"
        :disabled="!idList.length"
        class="mb10"
        size="mini"
        >导出数据</el-button
      >
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="订单编号" prop="order_sn"  width="120"/>
            <ElTableColumn label="客户名称" prop="customer_name" />
            <ElTableColumn label="核销人" prop="" />
            <ElTableColumn label="店铺名称" prop="shop_name" />
            <ElTableColumn label="市场名称" prop="market_name" />
            <ElTableColumn label="优惠券名称" prop="name" />
            <ElTableColumn label="优惠券类型" prop="type" />
            <ElTableColumn label="使用规则" prop="use_threshold">
              <template slot-scope="{ row }">
                <div v-if="row.use_threshold == 0">无限制</div>
                <div v-else>满{{row.use_threshold}}元可用</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="面值(元)" prop="use_threshold" />
            <ElTableColumn label="领取时间" prop="create_time"  width="100"/>
            <ElTableColumn label="使用时间" prop="use_time"  width="100"/>
         
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from '@/components/Pagination';
import { getWriteOff,deleteCard } from '@/api/card';
import { readAdminMarketList } from '@/api/shop';
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  customer_name = ''; 
  shop_name = ""; 
  market_id = ""; 
}
class Options {
  constructor () {
    this.market_id = [] 
  }
}
export default {
  name: 'CardList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: ''
    }
  },
computed: {
    ...mapGetters(['userInfo']),
  },
  mounted () {
    this.getList()
    this.getOptions();
  },
  methods: {
    // 获取选项
    getOptions() {
      readAdminMarketList().then(res => {
        this.options.market_id = res.list;
      });
    },
    // 获取列表
    getList (option) {
      if (option === 'search') this.page = new Page();
      getWriteOff({...this.search,...this.page}).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      })
    },
    
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
            this.delCard(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 改变表格选中状态
    handleSelectionChange (val) {
      this.idList = val.map((i) => {
        return i.id
      })
    },
    // 删除
    delCard(id){
      deleteCard({id:id}).then(res => {
        console.log(res)
        this.$message({
            type: "success",
            message: res.msg
          });
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
</style>
